<template>
  <v-container fill-height class="align-center justify-center d-flex cont">
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
        ><adv class="box ml-auto" />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <login_handler class="box mr-auto"
      /></v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "login",
  components: {
    login_handler: () => import("@/components/login_handler"),
    adv: () => import("@/components/home/adv")
  }
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .box {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
</style>
